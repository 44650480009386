import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GenericBackdrop from "./commons/GenericBackdrop";
import { SnackBar } from "./commons/alert";
import {
  Typography,
  TextField,
  Stack,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Amplify, Auth } from "aws-amplify";
import OTP from "./otp";
import { dataPerCountry } from "./utils/dataPerCountry";

const Login = () => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE,
    },
  });

  const [t] = useTranslation("global");
  const PREFIX_COGNITO = "COG";

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  const [queryString, setQueryString] = useState();
  const [showOtp, setShowOtp] = useState(false);

  const [code, setCode] = useState("");
  const [codeDisabled, setCodeDisabled] = useState(false);

  const [user, setUser] = useState(null);
  const [number, setNumber] = useState("");
  const [tmpNumber, setTmpNumber] = useState("");

  const [pin, setPin] = useState("");
  const [tmpPin, setTmpPin] = useState({
    password: "",
    showPassword: false,
  });

  const [markNumber, setMarkNumber] = useState("");
  const [formatNumber, setFormatNumber] = useState({});

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    let domain = window.location.hostname;
    if (!domain.includes("localhost")) domain = domain.split(".")[3];
    domain = domain.toUpperCase();

    setDataPerCountry(domain);
  }, []);

  const setDataPerCountry = (props) => {
    dataPerCountry.forEach((item) => {
      if (props === item.country) setCodeDisabled(true);

      if (props === item.country || props === item.code) {
        setCode(item.code);
        setMarkNumber(item.markNumber);
        setFormatNumber(item.formatNumber);
      }
    });
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "number") {
      setNumber(value.trim());
      setTmpNumber(value.trim());
    }

    if (name === "pin") {
      setPin(value);
      setTmpPin({ ...tmpPin, [prop]: value });
    }

    if (name === "codCountry") setDataPerCountry(value);
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();

    setTmpPin({
      ...tmpPin,
      showPassword: !tmpPin.showPassword,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let requestId = query.get("request_id");
    let merchantId = query.get("id");

    if (!requestId || !merchantId)
      return SnackBar(t("General.Message.MissingParameter"), "warning");

    setQueryString({
      request_id: requestId,
      merchant_id: merchantId,
    });

    handleLoaderOpen();

    try {
      const responseCognito = await Auth.signIn(
        "+" + code + number,
        PREFIX_COGNITO + pin
      );

      setUser(responseCognito);
      setShowOtp(true);
      setTmpNumber("");
      setTmpPin({
        password: "",
        showPassword: false,
      });

      handleLoaderClose();
    } catch (error) {
      handleLoaderClose();
      SnackBar(t("General.Message.ErrorService"), "error");
    }
  };

  return (
    <Fragment>
      {!showOtp && (
        <Stack
          direction="column"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "16px",
            fontFamily: "Readex Pro",
          }}
          padding={1}
        >
          <Stack justifyContent="center" alignItems="center" mt={4} mb={4}>
            <h2
              style={{
                fontFamily: "Readex Pro",
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "34px",
                letterSpacing: "0px",
                color: "#343C46",
              }}
            >
              {t(`Login.Title`)}
            </h2>
          </Stack>
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack sx={{ width: "40%" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="country-code-select-label">
                    {t("Login.CountryCode")}
                  </InputLabel>
                  <Select
                    inputProps={{ "data-testid": "select-option" }}
                    labelId="country-code-select-label"
                    defaultValue=""
                    value={code}
                    disabled={codeDisabled}
                    id="country-code"
                    label={t("Login.CountryCode")}
                    name="codCountry"
                    onChange={handleChange()}
                    sx={{ borderRadius: "16px", width: "100%" }}
                  >
                    {dataPerCountry.map((item, index) => (
                      <MenuItem value={item.code} key={item.code}>
                        <img
                          src={item.flag}
                          alt={item.country}
                          style={{ height: "20px", width: "20px" }}
                        />
                        &nbsp;
                        {item.country + " +" + item.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack sx={{ width: "60%" }}>
                <TextField
                  required
                  autoComplete="username"
                  id="outlined-number"
                  name="number"
                  value={tmpNumber}
                  label={t("Login.MobileNumber")}
                  onChange={handleChange()}
                  placeholder={markNumber}
                  inputProps={formatNumber}
                  InputProps={{
                    sx: {
                      borderRadius: "16px",
                      fontSize: "Readex Pro",
                      width: "100%",
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ width: "100%", marginTop: "40px" }} spacing={4}>
              <TextField
                required
                label={t("Login.Dual")}
                name="pin"
                variant="outlined"
                type={tmpPin.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="ShowPass"
                        onClick={handleClickShowPassword}
                      >
                        {tmpPin.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "16px",
                    fontSize: "Readex Pro",
                    width: "100%",
                  },
                }}
              />
            </Stack>
            <Stack mt={4} mb={4} justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                type="submit"
                data-testid="btn-login"
                sx={{
                  width: "100%",
                  height: "48px",
                  background: "#363853",
                  color: "white",
                  fontFamily: "Readex Pro",
                  margin: "10px 0px 20px 0px",
                  borderRadius: "16px",
                }}
              >
                {t("Login.Enter")}
              </Button>
            </Stack>
            <Stack mt={4}>
              <Typography variant="legend" paragraph>
                {t("Login.NewPassword")}
              </Typography>
            </Stack>
          </form>
        </Stack>
      )}
      {showOtp && (
        <OTP
          showOtp={showOtp}
          setShowOtp={setShowOtp}
          user={user}
          setUser={setUser}
          credentials={{
            number: number,
            password: PREFIX_COGNITO + pin,
            code: code,
          }}
          queryString={queryString}
        />
      )}
      <GenericBackdrop open={loader} />
    </Fragment>
  );
};
export default Login;
