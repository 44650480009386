import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import GenericBackdrop from "./commons/GenericBackdrop";
import { SnackBar } from "./commons/alert";
import Countdown from "react-countdown";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { Button, Dialog, DialogContent, LinearProgress } from "@mui/material";
import LinkAccount from "./link/LinkAccount";
import { Auth } from "aws-amplify";
import { MuiOtpInput } from "mui-one-time-password-input";

const OTP = ({ setShowOtp, user, setUser, credentials, queryString }) => {
  const [t] = useTranslation("global");

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const MFA_TYPE = "SMS_MFA";
  const TIMER_MILLISECONDS = 300000;
  const MINUTES_MILLISECONDS = 60000;
  const SECONDS_MILLISECONDS = 1000;
  const PRECISION_MILLISECONDS = 4;
  const PERCENT = 100;
  const OTP_LENGTH = 6;

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [key, setKey] = useState("");

  const [data, setData] = useState({
    date: Date.now(),
    delay: TIMER_MILLISECONDS,
  });

  const handleChange = (newValue) => {
    setOtp(newValue);
    if (showError) setShowError(false);
  };

  const handleResend = async (event) => {
    event.preventDefault();
    handleLoaderOpen();

    if (showError) setShowError(false);
    setOtp("");

    try {
      const responseCognito = await Auth.signIn(
        "+" + credentials.code + credentials.number,
        credentials.password
      );

      setUser(responseCognito);
      setShowOtp(true);

      const currentTime = Date.now();
      setKey(currentTime);
      setDisabled(false);
      setData({ date: currentTime, delay: TIMER_MILLISECONDS });

      handleLoaderClose();
    } catch (error) {
      handleLoaderClose();
      SnackBar(t("General.Message.ErrorService"), "error");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (otp.length < OTP_LENGTH) {
      setShowError(true);
      return;
    }
    handleLoaderOpen();

    try {
      const responseCognito = await Auth.confirmSignIn(user, otp, MFA_TYPE);
      setToken(responseCognito["signInUserSession"]["idToken"]["jwtToken"]);

      handleLoaderClose();
      handleOpen();
    } catch (error) {
      handleLoaderClose();
      SnackBar(t("General.Message.ErrorOTP"), "error");
    }
  };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const renderer = (props) => {
    if (!props.completed) {
      const percent =
        PERCENT -
        ((props.minutes * MINUTES_MILLISECONDS +
          props.seconds * SECONDS_MILLISECONDS) *
          PERCENT) /
          TIMER_MILLISECONDS;
      return (
        <Fragment>
          <span>
            {t("OTP.RemainingTime", {
              timer: props.formatted.minutes + ":" + props.formatted.seconds,
            })}
          </span>
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              backgroundColor: "#FABE00",
              borderRadius: 16,
              marginTop: "8px",
            }}
          />
        </Fragment>
      );
    }
  };

  return (
    <Box style={{ textAlign: "center", fontFamily: "Readex Pro" }}>
      <h2
        style={{
          fontFamily: "Readex Pro",
          fontSize: "28px",
          fontWeight: "600",
          lineHeight: "34px",
          letterSpacing: "0px",
          color: "#343C46",
          padding: "30px 0px 20px 0px",
        }}
      >
        {t("OTP.Title")}
      </h2>

      <Box style={{ marginTop: "10px", textAlign: "center " }} mb={2}>
        <Countdown
          date={data.date + data.delay}
          renderer={renderer}
          key={key}
          intervalDelay={SECONDS_MILLISECONDS}
          precision={PRECISION_MILLISECONDS}
          onComplete={(_) => {
            setDisabled(true);
          }}
        />
      </Box>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <Box
          style={{
            padding: "32px 20px",
            boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
            borderRadius: "24px",
          }}
        >
          <Box display="flex" justifyContent="center">
            <MuiOtpInput
              length={OTP_LENGTH}
              value={otp}
              onChange={handleChange}
              className="MuiOtp"
              validateChar={validateChar}
              TextFieldsProps={{
                placeholder: "-",
                sx: {
                  input: {
                    fontFamily: "Readex Pro",
                    fontWeight: 400,
                    fontSize: 36,
                    color: "#404A56",
                  },
                },
              }}
            />
          </Box>
          {showError && (
            <Box
              style={{
                background: "#FAEDEA",
                border: "1px solid #FAEDEA",
                borderRadius: "8px",
                padding: "16px 8px",
              }}
              mt={4}
            >
              <Box style={{ color: "#D95A3A", top: "10.42%", float: "left" }}>
                <WarningAmberIcon />
              </Box>
              <Box>{t(`OTP.IncorrectCode`)}</Box>
            </Box>
          )}
          <Box style={{ textAlign: "center", marginTop: "40px" }}>
            {t(`OTP.Question`)}
            <Button
              sx={{
                fontFamily: "Readex Pro",
                textDecoration: "none",
                fontSize: "16px",
                fontWeight: "600",
                color: "#404A56",
              }}
              onClick={async (e) => await handleResend(e)}
            >
              {t(`OTP.Resend`)}
            </Button>
          </Box>
        </Box>
        <Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              height: "48px",
              background: "#363853",
              color: "white",
              fontFamily: "Readex Pro",
              margin: "80px 0px 20px 0px",
              borderRadius: "16px",
            }}
            disabled={disabled}
          >
            {t("OTP.Next")}
          </Button>
        </Box>
      </form>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {!open ? null : <LinkAccount userData={queryString} token={token} />}
        </DialogContent>
      </Dialog>
      <GenericBackdrop open={loader} />
    </Box>
  );
};
export default OTP;
