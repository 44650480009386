const defaultMark = "";
const defaultFormat = { inputMode: "numeric", pattern: "[0-9]*" };

export const dataPerCountry = [
  {
    flag: require("../../assets/flags/bolivia.png"),
    code: "591",
    country: "BO",
    markNumber: defaultMark,
    formatNumber: defaultFormat,
  },
  {
    flag: require("../../assets/flags/guatemala.png"),
    code: "502",
    country: "GT",
    markNumber: defaultMark,
    formatNumber: defaultFormat,
  },
  {
    flag: require("../../assets/flags/honduras.png"),
    code: "504",
    country: "HN",
    markNumber: defaultMark,
    formatNumber: defaultFormat,
  },
  {
    flag: require("../../assets/flags/paraguay.png"),
    code: "595",
    country: "PY",
    markNumber: "9XXXXXXXX",
    formatNumber: { inputMode: "numeric", pattern: "[1-9][0-9]*" },
  },
  {
    flag: require("../../assets/flags/salvador.png"),
    code: "503",
    country: "SV",
    markNumber: defaultMark,
    formatNumber: defaultFormat,
  },
  {
    flag: require("../../assets/flags/nicaragua.png"),
    code: "505",
    country: "NC",
    markNumber: defaultMark,
    formatNumber: defaultFormat,
  },
  {
    flag: require("../../assets/flags/panama.png"),
    code: "507",
    country: "PA",
    markNumber: defaultMark,
    formatNumber: defaultFormat,
  },
];
