import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Button, LinearProgress } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Auth } from "aws-amplify";
import Countdown from "react-countdown";
import { MuiOtpInput } from "mui-one-time-password-input";
import GenericBackdrop from "./commons/GenericBackdrop";
import { SnackBar } from "./commons/alert";
import axiosMerchant from "./commons/axiosMerchant";

const OtpMail = (props) => {
  const [t] = useTranslation("global");

  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [loader, setLoader] = useState(false);
  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  const PERCENT = 100;
  const OTPMAIL_LENGTH = 6;
  const PRECISION_MILLISECONDS = 4;
  const SECONDS_MILLISECONDS = 1000;
  const TIMER_MILLISECONDS = 300000;
  const MINUTES_MILLISECONDS = 60000;

  const [data, setData] = useState({
    date: Date.now(),
    delay: TIMER_MILLISECONDS,
  });
  const [otp, setOtp] = useState("");
  const [key, setKey] = useState("");

  const [clickCount, setClickCount] = useState(1);
  const maxClicks = 3;
  const [isCountdownComplete, setIsCountdownComplete] = useState(false);
  const [showErrorAttempts, setShowErrorAttempts] = useState(false);

  const valAttemps = () => {
    if (clickCount >= maxClicks) {
      setDisabled(true);
      setIsCountdownComplete(true);
      setShowErrorAttempts(true);
    } else {
      SnackBar(t("General.Message.ErrorOTP"), "error");
    }
  };

  const handleValidateOtp = async (e) => {
    e.preventDefault();
    let res;
    const PARENT_URL = document.location.ancestorOrigins[0];

    if (otp.length < OTPMAIL_LENGTH) {
      setShowError(true);
      return;
    }

    handleLoaderOpen();

    try {
      setClickCount(clickCount + 1);

      const response_verification = await Auth.sendCustomChallengeAnswer(
        props.user,
        otp
      );
      props.setUser(response_verification);

      if (response_verification["signInUserSession"]) {
        Object.assign(axiosMerchant.defaults.headers, {
          "mts-token":
            response_verification["signInUserSession"]["idToken"]["jwtToken"],
        });

        await axiosMerchant
          .post("/login")
          .then((response) => {
            res = response;
            res.data.authToken = {
              AccessToken:
                response_verification["signInUserSession"]["accessToken"][
                  "jwtToken"
                ],
              IdToken:
                response_verification["signInUserSession"]["idToken"][
                  "jwtToken"
                ],
              RefreshToken:
                response_verification["signInUserSession"]["refreshToken"][
                  "token"
                ],
              ExpiresIn: 1800,
            };
            sendMessage(res, PARENT_URL);
          })
          .catch((error) => {
            sendMessage(error.response, PARENT_URL);
          });
      } else {
        valAttemps();
      }

      handleLoaderClose();
    } catch (_) {
      valAttemps();
      handleLoaderClose();
    }
  };

  const sendMessage = (response, url) => {
    window.parent.postMessage(
      { message: "LOGIN", data: response.data, code: response.status },
      url
    );
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    handleLoaderOpen();

    if (showError) setShowError(false);
    setOtp("");

    try {
      const responseCognito = await Auth.signIn(
        props.credentials.email,
        props.credentials.password
      );

      props.setUser(responseCognito);
      props.setShowOtp(true);

      const currentTime = Date.now();
      setKey(currentTime);
      setClickCount(1);
      setDisabled(false);
      setIsCountdownComplete(false);
      setShowErrorAttempts(false);
      setData({ date: currentTime, delay: TIMER_MILLISECONDS });

      handleLoaderClose();
    } catch (_) {
      handleLoaderClose();
      SnackBar(t("General.Message.ErrorService"), "error");
    }
  };

  const handleChangeValues = (newValue) => {
    setOtp(newValue);
    if (showError) setShowError(false);
  };

  const rendererTime = (props) => {
    if (!props.completed) {
      const percent =
        PERCENT -
        ((props.minutes * MINUTES_MILLISECONDS +
          props.seconds * SECONDS_MILLISECONDS) *
          PERCENT) /
          TIMER_MILLISECONDS;

      return (
        <div>
          <span>
            {t("OTP.RemainingTime", {
              timer: props.formatted.minutes + ":" + props.formatted.seconds,
            })}
          </span>
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              backgroundColor: "#FABE00",
              borderRadius: 16,
              marginTop: "8px",
            }}
          />
        </div>
      );
    }
  };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  return (
    <Box style={{ textAlign: "center", fontFamily: "Readex Pro" }}>
      <h2
        style={{
          fontFamily: "Readex Pro",
          fontSize: "28px",
          fontWeight: "600",
          lineHeight: "34px",
          letterSpacing: "0px",
          color: "#343C46",
          padding: "30px 0px 20px 0px",
        }}
      >
        {t("OTP.Title")}
      </h2>
      <Box style={{ marginTop: "10px", textAlign: "center " }} mb={2}>
        {!isCountdownComplete && (
          <Countdown
            key={key}
            date={data.date + data.delay}
            renderer={rendererTime}
            precision={PRECISION_MILLISECONDS}
            intervalDelay={SECONDS_MILLISECONDS}
            onComplete={(_) => {
              setDisabled(true);
            }}
          />
        )}
      </Box>
      <form
        onSubmit={async (event) => {
          await handleValidateOtp(event);
        }}
      >
        <Box
          style={{
            padding: "32px 20px",
            boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
            borderRadius: "24px",
          }}
        >
          <Box display="flex" justifyContent="center">
            <MuiOtpInput
              length={OTPMAIL_LENGTH}
              value={otp}
              validateChar={validateChar}
              onChange={handleChangeValues}
              className="MuiOtp"
              TextFieldsProps={{
                placeholder: "-",
                sx: {
                  input: {
                    fontFamily: "Readex Pro",
                    fontWeight: 400,
                    fontSize: 36,
                    color: "#404A56",
                  },
                },
              }}
            />
          </Box>
          {showError && (
            <Box
              mt={4}
              style={{
                background: "#FAEDEA",
                border: "1px solid #FAEDEA",
                borderRadius: "8px",
                padding: "16px 8px",
              }}
            >
              <Box style={{ color: "#D95A3A", top: "10.42%", float: "left" }}>
                <WarningAmberIcon />
              </Box>
              <Box>{t(`OTP.IncorrectCode`)}</Box>
            </Box>
          )}
          {showErrorAttempts && (
            <Box
              mt={4}
              style={{
                background: "#FAEDEA",
                border: "1px solid #FAEDEA",
                borderRadius: "8px",
                padding: "16px 8px",
              }}
            >
              <Box style={{ color: "#D95A3A", top: "10.42%", float: "left" }}>
                <WarningAmberIcon />
              </Box>
              <Box>{t(`OTP.maxAttempts`)}</Box>
            </Box>
          )}
          <Box style={{ textAlign: "center", marginTop: "40px" }}>
            {t(`OTP.Question`)}
            <Button
              onClick={async (e) => await handleResendOTP(e)}
              sx={{
                fontFamily: "Readex Pro",
                textDecoration: "none",
                fontSize: "16px",
                fontWeight: "600",
                color: "#404A56",
              }}
            >
              {t(`OTP.Resend`)}
            </Button>
          </Box>
        </Box>
        <Box>
          <Button
            type="submit"
            variant="contained"
            disabled={disabled}
            sx={{
              width: "100%",
              height: "48px",
              background: "#363853",
              color: "white",
              fontFamily: "Readex Pro",
              margin: "80px 0px 20px 0px",
              borderRadius: "16px",
            }}
          >
            {t("OTP.Next")}
          </Button>
        </Box>
      </form>
      <GenericBackdrop open={loader} />
    </Box>
  );
};
export default OtpMail;
